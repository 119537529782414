<template>
    <el-dialog :visible="visible" width="600px" @close="handleClose" @open="handleOpen" class="dialog" :title="title"
               :fullscreen="true">
        <el-form ref="form" :model="form" size="mini" label-position="top">
            <div v-if="reportList && reportList.length > 0">
<!--                <div style="width: 240px">-->
                    <el-tabs tab-position="left"  @tab-click="handleClick" v-model="activeName" style="height: calc(100vh - 100px);overflow: scroll">
                        <el-tab-pane :label="'HRA风险评估报告'" :name="index.toString()" v-for="(item,index) in reportList" :key="index" >
                            <div style="height: calc(100vh - 80px);overflow: scroll;padding:0 10px;border:1px solid #ccc;width: 100%" v-if="!item.files">
                                <myreport v-if="newReport" :form="report"/>
                                <iframe v-else :src="pdf_url" frameborder="0" :key="iframeKey" width="100%" style="width: 100%;height:calc(100vh - 80px)"></iframe>
                            </div>
                            <div v-else style="width: 100%">
                                <el-tabs tab-position="top" style="" @tab-click="handleClickpdf" v-model="fileactiveName" >
                                    <el-tab-pane :label="'HRA风险评估报告'" :name="index.toString()" v-for="(e,index) in item.files" :key="index" >
                                        <div style="height: calc(100vh - 140px);overflow: scroll;padding:0 10px;border:1px solid #ccc;width: 100%">
                                            <myreport v-if="newReport" :form="report"/>
                                            <iframe v-else :src="pdf_url" frameborder="0" :key="iframeKey" width="100%" style="width: 100%;height:calc(100vh - 80px)"></iframe>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </el-tab-pane>
                        <!--                        <div v-for="e, i in reportList" :key="i">-->
                        <!--                            <div v-if="e.files">-->
                        <!--&lt;!&ndash;                                <div v-for="f, j in e.files" :key="j" @click="setUrl(f.pdf_file)" class="nav">&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    HRA风险评估报告&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
                        <!--                                <el-tab-pane :label="'HRA风险评估报告'" :name="index" v-for="(item,index) in e.files" :key="index">-->
                        <!--                                    <myreport v-if="newReport" :form="report" />-->
                        <!--                                    <iframe v-else :src="pdf_url" frameborder="0" width="100%"-->
                        <!--                                            style="width: 100%;height:calc(100vh - 80px)"></iframe>-->
                        <!--                                </el-tab-pane>-->
                        <!--                            </div>-->
                        <!--                            <div v-else class="nav">-->
                        <!--&lt;!&ndash;                                <div @click="setPage(e)">&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    HRA风险评估报告&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
                        <!--                                <el-tab-pane :label="item.createTime + 'HRA风险评估报告'" :name="item.id" v-for="(item,index) in reportList" :key="index">-->
                        <!--                                    <myreport v-if="newReport" :form="report" />-->
                        <!--                                    <iframe v-else :src="pdf_url" frameborder="0" width="100%"-->
                        <!--                                            style="width: 100%;height:calc(100vh - 80px)"></iframe>-->
                        <!--                                </el-tab-pane>-->
                        <!--                            </div>-->
                        <!--                        </div>-->

                    </el-tabs>
                </div>
<!--                <div style="flex: 1">-->
<!--                    <myreport v-if="newReport" :form="report"/>-->
<!--                    <iframe v-else :src="pdf_url" frameborder="0" width="100%"-->
<!--                            style="width: 100%;height:calc(100vh - 80px)"></iframe>-->
<!--                </div>-->
<!--            </div>-->
            <el-empty v-else description="没有评估报告"></el-empty>
        </el-form>
    </el-dialog>
</template>

<script>
    import myreport from './personalAssessmentReport.vue'

    export default {
        components: {
            myreport
        },
        props: {
            visible: {
                default: false
            },
            form: {
                default: {
                    operation: {}
                }
            },
            title: {
                default: "评估结果"
            },
        },
        data() {
            return {
                newReport: false,
                activeName: null,
                fileactiveName:null,
                report: {},
                reportList: [],
                pdf_url: '',
                items:{},
                iframeKey:0
            }
        },
        watch: {
            form: {
                immediate: true,
                handler(val) {
                    this.getData()
                }
            }
        },
        methods: {
            handleClick() {
                this.iframeKey +=1
                var items = this.reportList[parseInt(this.activeName)]
                this.items = items
                this.setPage(items)
            },
            handleClickpdf(){
                this.iframeKey +=1
                var url = this.items.files[parseInt(this.fileactiveName)].pdf_file
                this.setUrl(url)
            },
            setPage(e) {
                if(this.items.files){
                    this.newReport = false
                }else {
                    this.newReport = true
                    if (e.report) {
                        this.report = e.report
                        this.report.guardian = e.guardian
                        this.report.level = e.level_tag
                        this.report.audience_tag = e.audience_tag
                        this.report.userLevel = e.user_level_tag
                    } else {
                        this.report = e.data
                    }
                }
            },
            setUrl(url) {
                // this.pdf_url = url
                // this.pdf_url = `/api/helthProgress/hra/pg_pdf/pdf_file/${url}`
                this.newReport = false
                let firstTwoChars = url.slice(0, 2);
                this.pdf_url = `/uploads/pdf_pg/${firstTwoChars}/${url}`
            },
            getData() {
                this.$http.get(`/healthProgress/pg_pdf/${this.form.id}`)
                    .then(resp => {
                        if (resp.data.data && resp.data.data.length > 0) {
                            this.reportList = resp.data.data
                            // let f = this.reportList[0].files
                            // if (f && f.length > 0) {
                            //     let firstTwoChars = f[0].pdf_file.slice(0, 2);
                            //     this.pdf_url = `/uploads/pdf_pg/${firstTwoChars}/${f[0].pdf_file}`
                            // } else {
                            //     this.reportList = []
                            // }
                        }
                    }).catch(err => {
                    this.$message.error(err.message)
                })
            },
            handleClose() {
                this.newReport = false
                this.$emit('close')
            },
            handleOpen() {
                this.activeName = "a"
                this.fileactiveName = "b"
                this.report = {}
                this.items = {}
                this.reportList = []
                this.pdf_url = ''
                this.iframeKey = 0
                this.getData()
            }
        },
        // mounted() {
        //     this.getData()
        // }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 0;
    }

    .nav {
        padding: 10px 20px;
        cursor: pointer;
    }
</style>
